import { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

export type CommonTypes = {
    variant?: 'primary' | 'secondary';
    children?: ReactNode;
    dataTestid?: string;
    className?: string;
};

export type ButtonTextTypes = CommonTypes & {
    type?: 'button';
    onClick: MouseEventHandler;
    disabled?: boolean;
    htmlAttributes?: HTMLAttributes<HTMLButtonElement>;
};

type ButtonTextSubmitTypes = CommonTypes & {
    type?: 'submit';
    onClick?: MouseEventHandler;
    disabled?: boolean;
    htmlAttributes?: HTMLAttributes<HTMLButtonElement>;
};

type LinkTypes = CommonTypes & {
    type?: 'link';
    to: string;
    htmlAttributes?: HTMLAttributes<HTMLAnchorElement>;
};

type ExternalLinkTypes = CommonTypes & {
    type?: 'external-link';
    href: string;
    htmlAttributes?: HTMLAttributes<HTMLAnchorElement>;
};

export type ButtonTextProps = ButtonTextTypes | ButtonTextSubmitTypes | LinkTypes | ExternalLinkTypes;

export const ButtonText = (props: ButtonTextProps) => {
    // Note : I did used props destructuration to work around a type error on `onClick`, `to` and `href` props, not defined depending on `type`

    const variant = props.variant || 'primary';
    const type = props.type || 'button';

    const classNames = clsx(
        'flex items-center gap-2 whitespace-nowrap rounded-md border border-solid border-transparent px-2 py-1 text-sm font-medium',
        'hover:bg-gray-100',
        'active:bg-gray-200',
        'disabled:cursor-not-allowed disabled:text-gray-300',
        {
            'text-black': variant === 'primary',
            'text-violet-600': variant === 'secondary',
        },
        props.className
    );

    if (!type || type === 'button') {
        const { onClick, disabled, htmlAttributes } = props as ButtonTextTypes;
        return (
            <button
                type='button'
                disabled={disabled}
                aria-disabled={disabled}
                onClick={onClick}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.children}
            </button>
        );
    } else if (type === 'submit') {
        const { htmlAttributes, disabled, onClick } = props as ButtonTextSubmitTypes;
        return (
            <button
                type='submit'
                disabled={disabled}
                aria-disabled={disabled}
                data-testid={props.dataTestid}
                className={classNames}
                onClick={onClick}
                {...htmlAttributes}
            >
                {props.children}
            </button>
        );
    } else if (type === 'link') {
        const { to, htmlAttributes } = props as LinkTypes;
        return (
            <Link
                to={to}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.children}
            </Link>
        );
    } else if (type === 'external-link') {
        const { href, htmlAttributes } = props as ExternalLinkTypes;
        return (
            <a
                href={href}
                data-testid={props.dataTestid}
                className={classNames}
                {...htmlAttributes}
            >
                {props.children}
            </a>
        );
    }
    return null;
};
