import { Navigate } from 'react-router-dom';
import React from 'react';
import { NavigateWithParams } from '@spacefill/shared/src/utils/RouterUtils';

const LogisticManagementMasterItemsNewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/New').then((module) => ({
        Component: module.LogisticManagementMasterItemsNew,
    }));
const LogisticManagementMasterItemsEditCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/Edit').then((module) => ({
        Component: module.LogisticManagementMasterItemsEdit,
    }));
const LogisticManagementMasterItemsListCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/List').then((module) => ({
        Component: module.LogisticManagementMasterItemsList,
    }));
const LogisticManagementMasterItemsViewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/View').then((module) => ({
        Component: module.LogisticManagementMasterItemsView,
    }));

const LogisticManagementWarehousesOrdersList = async () =>
    import('../../pages/logistic-management/warehouses/orders/List').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersList,
    }));
const LogisticManagementAccurateStockSituation = async () =>
    import('../../pages/logistic-management/inventory/accurate-stock-situation').then((module) => ({
        Component: module.LogisticManagementAccurateStockSituation,
    }));

const LogisticManagementOrdersImport = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/ImportOrders').then((module) => ({
        Component: module.LogisticManagementOrdersImport,
    }));

const LogisticManagementOrdersRedirectView = async () =>
    import('../../pages/logistic-management/orders/RedirectView').then((module) => ({
        Component: module.LogisticManagementOrdersRedirectView,
    }));
const LogisticManagementOrdersRedirectEdit = async () =>
    import('../../pages/logistic-management/orders/RedirectEdit').then((module) => ({
        Component: module.LogisticManagementOrdersRedirectEdit,
    }));

const LogisticManagementWarehousesOrdersView = async () =>
    import('../../pages/logistic-management/warehouses/orders/view/View').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersView,
    }));
const NewOrderInit = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Init').then((module) => ({
        Component: module.NewOrderInit,
    }));
const LogisticManagementWarehousesNewOrdersEntry = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Entry').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersEntry,
    }));
const LogisticManagementWarehousesNewOrdersExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Exit').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersExit,
    }));
const LogisticManagementWarehousesEditOrders = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/Edit').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrders,
    }));
const LogisticManagementWarehousesEditOrdersRedirectEntryOrExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/index').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
    }));

const CustomerKeyPerformanceIndicationView = async () =>
    import('../../pages/logistic-management/analytics/View/View').then((module) => ({ Component: module.DataView }));

const LogisticManagementDocumentsView = async () =>
    import('../../pages/logistic-management/documents/View').then((module) => ({
        Component: module.LogisticManagementDocumentsView,
    }));

const IncidentsList = async () =>
    import('../../pages/logistic-management/incidents/list/List').then((module) => ({
        Component: module.IncidentsList,
    }));

const getCustomerLogisticManagementRoutes = (user) => {
    if (user.role !== 'SHIPPER' || (user?.customerWarehouseConfigurations ?? []).length === 0) {
        return [];
    }

    return [
        // Global routes

        // Data View
        {
            path: '/logistic-management/analytics/',
            lazy: CustomerKeyPerformanceIndicationView,
            condition: user?.features?.hasAnalyticsEnabled,
        },

        // Incidents List
        {
            path: '/logistic-management/incidents/',
            lazy: IncidentsList,
        },
        {
            path: '/logistic-management/incidents/view/:viewId',
            lazy: IncidentsList,
        },

        //  Documents
        {
            path: '/documents/',
            lazy: LogisticManagementDocumentsView,
        },

        // Orders
        {
            path: '/logistic-management/orders/',
            element: <Navigate to='/logistic-management/exit-orders/' />,
        },
        {
            path: '/logistic-management/entry-orders/',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/entry-orders/view/:viewId',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/exit-orders/',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/exit-orders/view/:viewId',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/entry-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/exit-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },

        {
            path: '/logistic-management/orders/:orderId/',
            lazy: LogisticManagementOrdersRedirectView,
        },
        {
            path: '/logistic-management/orders/import/',
            lazy: LogisticManagementOrdersImport,
        },
        {
            path: '/logistic-management/orders/:orderId/edit',
            lazy: LogisticManagementOrdersRedirectEdit,
        },

        // Master items
        {
            path: '/logistic-management/inventory/items/new',
            lazy: LogisticManagementMasterItemsNewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/edit',
            lazy: LogisticManagementMasterItemsEditCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/',
            lazy: LogisticManagementMasterItemsViewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/',
            lazy: LogisticManagementMasterItemsListCustomUnit,
        },

        // Stock situation
        {
            path: '/logistic-management/inventory/stock-situation/references/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/forecasted/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },

        // By warehouse

        // Orders
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/',
            element: (
                <NavigateWithParams
                    to={(params) => `/logistic-management/warehouses/${params.warehouseId}/exit-orders/`}
                />
            ),
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/view/:viewId',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/',
            lazy: LogisticManagementWarehousesOrdersList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/view/:viewId',
            lazy: LogisticManagementWarehousesOrdersList,
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/new',
            lazy: LogisticManagementWarehousesNewOrdersEntry,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/new',
            lazy: LogisticManagementWarehousesNewOrdersExit,
            conditionFailedRedirect: '/',
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-entry',
            lazy: LogisticManagementWarehousesNewOrdersEntry,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-exit',
            lazy: LogisticManagementWarehousesNewOrdersExit,
            conditionFailedRedirect: '/',
        },

        // View order
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/',
            lazy: LogisticManagementOrdersRedirectView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/:orderId/',
            lazy: LogisticManagementWarehousesOrdersView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/:orderId/',
            lazy: LogisticManagementWarehousesOrdersView,
        },

        // Edit order
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrders,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrders,
        },

        // Stock situation
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/last-snapshot',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/last-snapshot',
            lazy: LogisticManagementAccurateStockSituation,
        },
    ];
};

export { getCustomerLogisticManagementRoutes };
