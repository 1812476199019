const EditTransport = async () =>
    import('../../pages/logistic-management/transports/Edit').then((module) => ({
        Component: module.EditTransport,
    }));

const LogisticManagementTransportsList = async () =>
    import('../../pages/logistic-management/transports/List').then((module) => ({
        Component: module.LogisticManagementTransportsList,
    }));

const LogisticManagementNewTransport = async () =>
    import('../../pages/logistic-management/transports/New').then((module) => ({
        Component: module.LogisticManagementNewTransport,
    }));

const LogisticManagementTransportView = async () =>
    import('../../pages/logistic-management/transports/View').then((module) => ({
        Component: module.LogisticManagementTransportView,
    }));

// Only LSP routes
const NewTransportInit = async () =>
    import('../../pages/logistic-management/transports/Init').then((module) => ({
        Component: module.NewTransportInit,
    }));

const UnconfirmedTransportsList = async () =>
    import('../../pages/logistic-management/unconfirmed-transports/List').then((module) => ({
        Component: module.UnconfirmedTransportsList,
    }));
const UnconfirmedTransportView = async () =>
    import('../../pages/logistic-management/unconfirmed-transports/View').then((module) => ({
        Component: module.UnconfirmedTransportView,
    }));

const getTransportsRoutes = (user) => {
    // If user has no transport contract, return empty array
    if (!user?.transport?.hasTransportContract) {
        return [];
    }

    const transportRoutes =
        user.customerWarehouseConfigurations.length === 0
            ? [
                  //  Transports
                  {
                      path: '/transports/',
                      lazy: LogisticManagementTransportsList,
                  },
                  {
                      path: '/transports/new',
                      lazy: LogisticManagementNewTransport,
                  },

                  {
                      path: '/transports/:transportId/',
                      lazy: LogisticManagementTransportView,
                  },
                  {
                      path: '/transports/:transportId/edit',
                      lazy: EditTransport,
                  },
              ]
            : [];

    if (user.role === 'PROVIDER' && user.customerWarehouseConfigurations.length === 0) {
        transportRoutes.push(
            //  Transports
            {
                path: '/transports/init',
                lazy: NewTransportInit,
                conditionFailedRedirect: '/',
            },
            // Unconfirmed transports
            {
                path: '/unconfirmed-transports/',
                lazy: UnconfirmedTransportsList,
            },
            {
                path: '/unconfirmed-transports/:transportId/',
                lazy: UnconfirmedTransportView,
            }
        );
    }

    return transportRoutes;
};

export { getTransportsRoutes };
