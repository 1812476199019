import { HTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

export interface BreadcrumbItemProps {
    children: ReactNode;
    href: string;
    state: 'default' | 'active';
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLLIElement>;
}

export const BreadcrumbItem = ({
    children,
    href,
    state = 'default',
    dataTestid,
    htmlAttributes,
}: BreadcrumbItemProps) => (
    <li
        className={clsx(['px-2', 'w-fit', 'text-gray-600', 'text-xs', 'hover:underline'], {
            'font-bold pointer-events-none': state === 'active',
        })}
        data-testid={dataTestid}
        {...htmlAttributes}
    >
        <Link
            className='inline-block rounded px-2 py-1 outline-0 focus-visible:border focus-visible:border-solid focus-visible:border-violet-400'
            to={href}
        >
            {children}
        </Link>
    </li>
);
