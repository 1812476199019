import { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { clsx } from 'clsx';
import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useTransitionStyles,
} from '@floating-ui/react';

export interface TooltipProps {
    content: string | ReactNode;
    variant?: 'default' | 'with-arrow';
    position?: 'bottom' | 'top' | 'left' | 'right';
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    buttonAttributes?: HTMLAttributes<HTMLDivElement>;
    children: ReactNode;
    disabled?: boolean;
}

export const Tooltip = ({
    content,
    position = 'top',
    variant = 'default',
    dataTestid,
    htmlAttributes,
    buttonAttributes,
    children,
    disabled = false,
}: TooltipProps) => {
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen && !disabled,
        onOpenChange: setIsOpen,
        placement: position,
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
            arrow({ element: arrowRef }),
        ],
    });

    // Event listeners to change the open state
    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const { styles } = useTransitionStyles(context);

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss]);

    return (
        <>
            <div
                role='button'
                data-testid={dataTestid}
                ref={refs.setReference}
                {...getReferenceProps()}
                {...buttonAttributes}
                className={clsx('inline-block', { 'cursor-default': disabled })}
            >
                {children}
            </div>
            <FloatingPortal>
                {isOpen && !disabled && (
                    <div
                        className={clsx([
                            'whitespace-break-spaces',
                            'pointer-events-none',
                            'rounded-md bg-white p-2 text-xs text-gray-600 drop-shadow-lg',
                            'z-tooltip',
                        ])}
                        ref={refs.setFloating}
                        style={floatingStyles}
                        data-testid={dataTestid && `${dataTestid}-content`}
                        {...htmlAttributes}
                        {...getFloatingProps()}
                    >
                        <div style={styles}>
                            {content}
                            {variant === 'with-arrow' && (
                                <FloatingArrow
                                    className={'fill-white'}
                                    ref={arrowRef}
                                    context={context}
                                />
                            )}
                        </div>
                    </div>
                )}
            </FloatingPortal>
        </>
    );
};
