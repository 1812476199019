import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import {
    faBook,
    faBoxesStacked,
    faBuilding,
    faChartSimple,
    faCircleQuestion,
    faCommentsAlt,
    faDiamondExclamation,
    faFileMagnifyingGlass,
    faFolderOpen,
    faGear,
    faGraduationCap,
    faHandHoldingBox,
    faLayerPlus,
    faPlugCircleBolt,
    faQuestionCircle,
    faUser,
    faWarehouseFull,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faTruck,
} from '@fortawesome/pro-light-svg-icons';
import { Sidebar } from '@spacefill/uikit/src/components/Sidebar';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { PremiumAccessModal } from '@spacefill/uikit/src/business-components/modals/PremiumAccessModal';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';
import { call } from '@spacefill/shared/src/utils/Backend';

import { UserAdminSwitcher } from './UserSwitcher';

const ExternalConsoleLeftNav = () => {
    const { t } = useTranslation();
    const { user, switchRole } = useUserContext();
    const location = useLocation();
    const attachmentsUrl = getEnvironmentVariable('attachmentsUrl');
    const [isAnalyticsModalOpen, setAnalyticsModalOpen] = useState(false);

    const isStartUser = user?.role === 'SHIPPER' && !user?.features?.orderManagementEnabled;

    let logo = null;
    if (user?.role === 'SHIPPER' && user.customer.platformLogoSvg) {
        logo = `data:image/svg+xml;base64,${btoa(user.customer.platformLogoSvg.trim())}`;
    } else if (user?.role === 'PROVIDER' && user.logisticProvider.platformLogoSvg) {
        logo = `data:image/svg+xml;base64,${btoa(user.logisticProvider.platformLogoSvg.trim())}`;
    }

    let linkTrainingCenter = '';
    switch (user?.preferredLanguage) {
        case 'DE':
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'wtrTlUxO' : 'cAyzu1Cm';
            if (isStartUser) {
                linkTrainingCenter = 'ouMj4kTu';
            }
            break;
        case 'FR':
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'S8X42CWA' : 'VTMW7XPu';
            if (isStartUser) {
                linkTrainingCenter = 'QbDGf4MT';
            }
            break;
        default:
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'xRBC4Mg7' : 'mRqv6s0J';
            if (isStartUser) {
                linkTrainingCenter = 'ggQbgZBL';
            }
            break;
    }

    const { data: pdfConfigs } = useReactQuery({
        queryKey: ['email-to-order/pdf-configuration/getAll'],
        queryFn: () => call('email-to-order/pdf-configuration/getAll', {}, 'POST'),
        placeholderData: [],
    });

    const { data: countObject } = useReactQuery({
        queryKey: ['unconfirmedorders/getAll'],
        queryFn: () => call('/unconfirmedorders/getAll', {}, 'GET'),
        placeholderData: {},
        enabled: !!user.warehouseIds.length,
    });

    const { data: unconfirmedTransports } = useReactQuery({
        queryKey: ['unconfirmed-transports/get-all'],
        queryFn: async () => {
            return await call('/unconfirmed-transports/get-all', {}, 'GET');
        },
        enabled: !!user.logisticProvider.id && user.customerWarehouseConfigurations.length === 0,
        placeholderData: [],
    });

    return (
        <Fragment>
            <Sidebar
                logo={logo}
                showUserSwitcher={user.isAdmin && <UserAdminSwitcher />}
                mainNavigation={[
                    ...(user.warehouseIds.length > 0
                        ? [
                              {
                                  title: t('Entry_plural'),
                                  icon: (
                                      <FontAwesomeIcon
                                          icon={faArrowRightToBracket}
                                          className='rotate-180'
                                      />
                                  ),
                                  isActive: !!location.pathname.match(
                                      /\/logistic-management\/(warehouses\/[a-f0-9-]+\/)?entry-orders\/?/
                                  ),
                                  to: '/logistic-management/entry-orders/',
                                  dataTestid: 'nav-entry-orders',
                                  subItems: user.warehouses.length > 1 && [
                                      {
                                          title: t('All warehouses'),
                                          isActive: location.pathname === '/logistic-management/entry-orders/',
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          bottomDivider: true,
                                          to: '/logistic-management/entry-orders/',
                                      },
                                      ...user.warehouses.map((warehouse) => ({
                                          title: warehouse.label,
                                          isActive: !!location.pathname.match(
                                              new RegExp(
                                                  `/logistic-management/warehouses/${warehouse.id}/entry-orders/`
                                              )
                                          ),
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          logoUrl: warehouse?.logisticProvider?.logoUrl
                                              ? `${attachmentsUrl}/${warehouse?.logisticProvider?.logoUrl}`
                                              : '',
                                          to: `/logistic-management/warehouses/${warehouse.id}/entry-orders/`,
                                      })),
                                  ],
                              },
                              {
                                  title: t('Exit_plural'),
                                  icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
                                  isActive: !!location.pathname.match(
                                      /\/logistic-management\/(warehouses\/[a-f0-9-]+\/)?exit-orders\/?/
                                  ),
                                  to: '/logistic-management/exit-orders/',

                                  dataTestid: 'nav-exit-orders',
                                  subItems: user.warehouses.length > 1 && [
                                      {
                                          title: t('All warehouses'),
                                          isActive: location.pathname === '/logistic-management/exit-orders/',
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          bottomDivider: true,
                                          to: '/logistic-management/exit-orders/',
                                      },
                                      ...user.warehouses.map((warehouse) => ({
                                          title: warehouse.label,
                                          isActive: !!location.pathname.match(
                                              new RegExp(`/logistic-management/warehouses/${warehouse.id}/exit-orders/`)
                                          ),
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          logoUrl: warehouse?.logisticProvider?.logoUrl
                                              ? `${attachmentsUrl}/${warehouse?.logisticProvider?.logoUrl}`
                                              : '',
                                          to: `/logistic-management/warehouses/${warehouse.id}/exit-orders/`,
                                      })),
                                  ],
                              },
                              {
                                  title: t('Stock situation'),
                                  icon: <FontAwesomeIcon icon={faBoxesStacked} />,
                                  isActive: !!location.pathname.match(
                                      /\/logistic-management\/(warehouses\/[a-f0-9-]+\/)?inventory\/stock-situation\/?/
                                  ),
                                  to: '/logistic-management/inventory/stock-situation/references/',
                                  dataTestid: 'nav-stock',
                                  subItems: user.warehouses.length > 1 && [
                                      {
                                          title: t('All warehouses'),
                                          isActive:
                                              location.pathname ===
                                              '/logistic-management/inventory/stock-situation/references/',
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          bottomDivider: true,
                                          to: '/logistic-management/inventory/stock-situation/references/',
                                      },
                                      ...user.warehouses.map((warehouse) => ({
                                          title: warehouse.label,
                                          isActive:
                                              location.pathname ===
                                              `/logistic-management/warehouses/${warehouse.id}/inventory/stock-situation/references/`,
                                          icon: (
                                              <FontAwesomeIcon
                                                  className='w-8'
                                                  icon={faWarehouseFull}
                                              />
                                          ),
                                          hasRoundedIcon: true,
                                          logoUrl: warehouse?.logisticProvider?.logoUrl
                                              ? `${attachmentsUrl}/${warehouse?.logisticProvider?.logoUrl}`
                                              : '',
                                          to: `/logistic-management/warehouses/${warehouse.id}/inventory/stock-situation/references/`,
                                      })),
                                  ],
                              },
                          ]
                        : []),
                    ...(user?.transport?.hasTransportContract && user.customerWarehouseConfigurations.length === 0
                        ? [
                              {
                                  title: t('Transports'),
                                  icon: <FontAwesomeIcon icon={faTruck} />,
                                  isActive: location.pathname.includes('/transports/'),
                                  to: '/transports/',
                                  dataTestid: 'nav-transports-orders',
                              },
                          ]
                        : []),
                    ...(user.warehouseIds.length > 0
                        ? [
                              {
                                  title: t('References'),
                                  icon: <FontAwesomeIcon icon={faBook} />,
                                  isActive: location.pathname.includes('/logistic-management/inventory/items/'),
                                  to: '/logistic-management/inventory/items/',
                                  dataTestid: 'nav-ref',
                              },
                              {
                                  title: t('Analytics'),
                                  icon: <FontAwesomeIcon icon={faChartSimple} />,
                                  isProFeature: user?.features?.hasAnalyticsEnabled === false,
                                  isActive: location.pathname === '/logistic-management/analytics/',
                                  to: user?.features?.hasAnalyticsEnabled
                                      ? '/logistic-management/analytics/'
                                      : undefined,
                                  dataTestid: 'nav-analytics',
                              },
                              {
                                  title: t('Incidents'),
                                  icon: <FontAwesomeIcon icon={faDiamondExclamation} />,
                                  isActive: !!location.pathname.match(/\/logistic-management\/incidents\/?/),
                                  to: '/logistic-management/incidents/',
                                  dataTestid: 'nav-incidents',
                              },
                              {
                                  title: t('Documents'),
                                  icon: <FontAwesomeIcon icon={faFolderOpen} />,
                                  isActive: location.pathname === '/documents/',
                                  to: '/documents/',
                                  dataTestid: 'nav-documents',
                              },
                          ]
                        : []),

                    ...(user?.logisticProvider?.company?.includes('DERET')
                        ? [
                              {
                                  title: t('Integrations'),
                                  icon: <FontAwesomeIcon icon={faPlugCircleBolt} />,
                                  isActive: location.pathname.startsWith('/logistic-management/integrations/'),
                                  to: '/logistic-management/integrations/',
                                  dataTestid: 'nav-integrations',
                              },
                          ]
                        : []),

                    ...(pdfConfigs && pdfConfigs.length > 0 && user.role === 'PROVIDER'
                        ? [
                              {
                                  title: t('Orders to review'),
                                  icon: <FontAwesomeIcon icon={faFileMagnifyingGlass} />,
                                  isActive: location.pathname.includes('/logistic-management/unconfirmed-orders/'),
                                  to: '/logistic-management/unconfirmed-orders/',
                                  countValue: countObject ? countObject[1] : undefined,
                                  dataTestid: 'storage-emails',
                              },
                          ]
                        : []),
                    ...(user.role === 'PROVIDER' &&
                    unconfirmedTransports &&
                    user?.transport?.hasTransportContract &&
                    user.customerWarehouseConfigurations.length === 0
                        ? [
                              {
                                  title: t('Transports to review'),
                                  icon: <FontAwesomeIcon icon={faFileMagnifyingGlass} />,
                                  isActive: location.pathname.includes('/unconfirmed-transports/'),
                                  to: '/unconfirmed-transports/',
                                  countValue: unconfirmedTransports[1],
                                  dataTestid: 'nav-unconfirmed-transports-orders',
                              },
                          ]
                        : []),
                ]}
                bottomNavigation={[
                    {
                        title: t('Support'),
                        icon: <FontAwesomeIcon icon={faCircleQuestion} />,
                        subItems: [
                            {
                                title: t('Contact us'),
                                icon: <FontAwesomeIcon icon={faCommentsAlt} />,
                                href:
                                    user?.preferredLanguage === 'FR'
                                        ? `https://share.hsforms.com/1kZiXiThKTPyswL3sMULDkw4gsew?email=${
                                              user.email || ''
                                          }`
                                        : user?.preferredLanguage === 'DE'
                                        ? `https://share.hsforms.com/1LBgZ6T3IStyU8lNqtywI_g4gsew?email=${
                                              user.email || ''
                                          }`
                                        : `https://share.hsforms.com/10jZcnqXgR-W_9-CDlcusoA4gsew?email=${
                                              user.email || ''
                                          }`,
                                target: '_blank',
                            },
                            {
                                title: t('Help Center'),
                                icon: <FontAwesomeIcon icon={faQuestionCircle} />,
                                href:
                                    user?.preferredLanguage === 'FR'
                                        ? 'https://help.spacefill.eu/knowledge'
                                        : user?.preferredLanguage === 'DE'
                                        ? 'https://help.spacefill.eu/de/knowledge'
                                        : 'https://help.spacefill.eu/en/knowledge',
                                target: '_blank',
                            },
                            ...(user?.role === 'SHIPPER' || user?.role === 'PROVIDER'
                                ? [
                                      {
                                          title: t('Register for the training'),
                                          icon: <FontAwesomeIcon icon={faGraduationCap} />,
                                          href: `https://spacefill.typeform.com/to/${linkTrainingCenter}`,
                                          target: '_blank',
                                      },
                                  ]
                                : []),
                        ],
                    },
                    {
                        title: t('Settings'),
                        icon: <FontAwesomeIcon icon={faGear} />,
                        dataTestid: 'nav-settings',
                        isActive: location.pathname.startsWith('/settings/'),
                        to: '/settings/users/',
                    },
                ]}
                profileTopNavigation={
                    user.multiRoles
                        ? [
                              {
                                  title: t('Operator view'),
                                  hasRoundedIcon: true,
                                  icon: <FontAwesomeIcon icon={faWarehouseFull} />,
                                  disabled: user.role === 'PROVIDER',
                                  dataTestid: `nav-switch-operator${user.role === 'PROVIDER' ? '-disabled' : ''}`,
                                  onClick: async () => await switchRole('PROVIDER'),
                              },
                              {
                                  title: t('Shipper view'),
                                  hasRoundedIcon: true,
                                  icon: <FontAwesomeIcon icon={faHandHoldingBox} />,
                                  disabled: user.role === 'SHIPPER',
                                  dataTestid: `nav-switch-shipper${user.role === 'SHIPPER' ? '-disabled' : ''}`,
                                  onClick: async () => await switchRole('SHIPPER'),
                              },
                          ]
                        : []
                }
                profileNavigation={[
                    {
                        title: t('Company profile'),
                        icon: <FontAwesomeIcon icon={faBuilding} />,
                        dataTestid: 'nav-company',
                        isActive: location.pathname.startsWith('/company-profile/'),
                        to: '/company-profile/',
                    },
                    {
                        title: t('User profile'),
                        icon: <FontAwesomeIcon icon={faUser} />,
                        dataTestid: 'nav-profile',
                        isActive: location.pathname.startsWith('/profile/'),
                        to: '/profile/',
                    },
                    {
                        title: t('Add new warehouses'),
                        icon: <FontAwesomeIcon icon={faLayerPlus} />,
                        dataTestid: 'nav-new-warehouse',
                        href: `https://help-spacefill.zendesk.com/hc/${
                            (user?.preferredLanguage ?? 'EN') === 'EN' ? 'en-us' : user?.preferredLanguage.toLowerCase()
                        }/requests/new?ticket_form_id=5609478305169&tf_anonymous_requester_email=${
                            user?.email
                        }&tf_1900005544053=shipper&tf_description=${t(
                            'I would like to add a new warehouse to my network'
                        )}`,
                        target: '_blank',
                    },
                    {
                        title: t('Log out'),
                        dataTestId: 'nav-logout',
                        icon: <FontAwesomeIcon icon={faArrowRightToBracket} />,
                        to: '/logout/',
                    },
                ]}
                userInfo={{
                    userName: `${user?.firstName} ${user?.lastName}`,
                    company: user?.customer?.company ?? user?.logisticProvider?.company,
                    role: user?.role,
                }}
            />

            <PremiumAccessModal
                contentKey='analytics'
                featureNameForTracking='kpi_page_sidebar'
                isOpen={isAnalyticsModalOpen}
                onClose={() => setAnalyticsModalOpen(false)}
                user={user}
                dataTestid='analytics-premium-access-modal'
            />
        </Fragment>
    );
};

export { ExternalConsoleLeftNav };
