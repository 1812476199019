import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { InAppNotification } from '@spacefill/uikit/src/components/InAppNotification';
import { Divider } from '@spacefill/uikit/src/components/Divider';
import * as Backend from '@spacefill/shared/src/utils/Backend';
import { EmptyState } from '@spacefill/uikit/src/components/EmptyState';
import { Loader } from '@spacefill/uikit/src/components/Loader';
import { getWarehouseLabel } from '@spacefill/shared/src/components/warehouses/configuration/Helpers';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import { toast } from '@spacefill/uikit/src/components/Toast/createToast';

import emptyNotificationSVG from '../../assets/empty-states/empty-state-notification.svg';

import { templates } from './InAppNotificationsTemplates';

const PER_PAGE = 10;

export function InAppNotificationsList() {
    const { user } = useUserContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['in-app-notifications'],
        queryFn: ({ pageParam = 0 }) =>
            Backend.call('in-app-notifications', { limit: PER_PAGE, offset: pageParam * PER_PAGE }),
        placeholderData: { data: [], count: 0 },
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage ?? undefined; // undefined is important to have `hasNextPage` false
        },
    });

    const { mutate: readMutate } = useMutation({
        mutationFn: ({ notificationId }) => Backend.call(`in-app-notifications/${notificationId}/read`, {}, 'POST'),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['in-app-notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['in-app-notifications/count/unread'],
            });
        },
        onError(error) {
            console.error('error during read mutation: ', error);
            toast.error(t('Internal error!'));
        },
    });

    const observer = useRef();
    const lastProjectElementRef = useCallback(
        (node) => {
            if (isFetchingNextPage) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new window.IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    );

    const totalCount = data?.pages?.[0]?.count;
    return (
        <div>
            {totalCount === 0 && (
                <EmptyState
                    picture={{
                        src: emptyNotificationSVG,
                        width: 80,
                        height: 121,
                    }}
                    paragraph={t('No notification yet. New comments and alerts will be displayed here.')}
                />
            )}
            {(data.pages || []).map((notifications, i) => {
                return (
                    <React.Fragment key={i}>
                        {(notifications.data || []).map((notification) => {
                            if (!(notification.templateId in templates)) {
                                return null;
                            }
                            const notificationTemplate = templates[notification.templateId];
                            return (
                                <Link
                                    key={`InAppNotification-${notification.id}`}
                                    to={notificationTemplate.url(notification)}
                                    onClick={() => readMutate({ notificationId: notification.id })}
                                >
                                    <InAppNotification
                                        key={notification.id}
                                        title={notificationTemplate.title(notification)}
                                        titleIcon={notificationTemplate.icon(notification)}
                                        user={notification.templateParams.createdBy}
                                        receivedAt={new Date(notification.createdAt)}
                                        warehouseName={getWarehouseLabel(user, notification.templateParams.warehouse)}
                                        unread={!notification.readAt}
                                        customerCompany={notification.templateParams?.customer?.company}
                                        providerCompany={notification.templateParams?.logisticProvider?.company}
                                    >
                                        {notificationTemplate.content(notification)}
                                    </InAppNotification>
                                    <Divider />
                                </Link>
                            );
                        })}
                    </React.Fragment>
                );
            })}
            {hasNextPage && (
                <div ref={lastProjectElementRef}>
                    <Loader />
                </div>
            )}
        </div>
    );
}
