import React from 'react';
import { Navigate } from 'react-router-dom';

export const getLogisticProviderIntegrationsRoutes = () => {
    return [
        {
            path: '/logistic-management/integrations/',
            element: <Navigate to='/logistic-management/integrations/jobs/' />,
            redirectFrom: '/logistic-management/integrations',
        },
        {
            path: '/logistic-management/integrations/jobs/',
            lazy: () =>
                import('../../pages/integrations/List').then((module) => ({ Component: module.IntegrationsList })),
        },
        {
            path: '/logistic-management/integrations/jobs/view/:viewId',
            lazy: () =>
                import('../../pages/integrations/List').then((module) => ({ Component: module.IntegrationsList })),
        },
    ];
};
