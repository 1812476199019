import { useState, useEffect } from 'react';

import breakpoints from '../../tailwind-breakpoints';

type BreakpointKey = keyof typeof breakpoints;

export function useMediaQuery(breakpoint: BreakpointKey): boolean {
    const query = `(max-width: ${breakpoints?.[breakpoint]})`;
    const [matches, setMatches] = useState(() => (window?.matchMedia ? window.matchMedia(query).matches : false));

    useEffect(() => {
        const media = window?.matchMedia ? window.matchMedia(query) : false;
        const handleChange = (event: MediaQueryListEvent) => setMatches(event.matches);

        if (media) {
            media.addEventListener('change', handleChange);
            return () => media.removeEventListener('change', handleChange);
        }
    }, [query]);

    return matches;
}
