import { HTMLAttributes } from 'react';
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import logoQuick from './loading-quick.svg';
import logoLong from './loading-long.svg';

export interface LoaderProps {
    variant?: 'quick' | 'long';
    size?: 'default' | 'small';
    loadingMessage?: string;
    className?: ClassValue;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const Loader = ({
    variant = 'quick',
    size = 'default',
    loadingMessage,
    className,
    dataTestid,
    htmlAttributes,
}: LoaderProps) => (
    <div
        className={twMerge(clsx('flex flex-col items-center gap-2', className))}
        data-testid={dataTestid}
        role='status'
        {...htmlAttributes}
    >
        {variant === 'quick' ? (
            <img
                className='animate-spin'
                alt='loading-quick'
                src={logoQuick}
                height={size === 'small' ? 16 : 35}
                width={size === 'small' ? 16 : 35}
            />
        ) : (
            <div className='relative'>
                <img
                    className='absolute opacity-10'
                    alt='loading-long'
                    src={logoLong}
                    height={size === 'small' ? 24 : 41}
                    width={size === 'small' ? 24 : 41}
                />
                <img
                    className='animate-[fillAndFade_1.5s_ease-out_infinite]'
                    src={logoLong}
                    height={size === 'small' ? 24 : 41}
                    width={size === 'small' ? 24 : 41}
                />
            </div>
        )}
        {loadingMessage && variant === 'long' && <p className='text-gray-500'>{loadingMessage}</p>}
    </div>
);
