import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';

import { getCustomerLogisticManagementRoutes } from './routes/CustomerRoutes';
import { getLogisticProviderLogisticManagementRoutes } from './routes/LogisticProviderRoutes';
import { getWarehouseRoutes } from './routes/WarehousesRoutes';
import { getSettingsRoutes } from './routes/SettingsRoutes';
import { getLogisticProviderIntegrationsRoutes } from './routes/IntegrationsRoutes';
import { getTransportsRoutes } from './routes/TransportRoutes';

const Profile = async () =>
    import('../pages/profile/Edit').then((module) => ({
        Component: module.ProfilePage,
    }));
const CustomerProfilePage = async () =>
    import('../pages/company-profile/CustomerProfile').then((module) => ({
        Component: module.CustomerProfilePage,
    }));
const ProviderProfilePage = async () =>
    import('../pages/company-profile/ProviderProfile').then((module) => ({
        Component: module.ProviderProfilePage,
    }));
const UsersList = async () =>
    import('../pages/settings/users/List').then((module) => ({
        Component: module.UsersList,
    }));

export const getLoggedUserRoutes = (user, features) => {
    return [
        // Profil
        {
            path: '/profile/',
            lazy: Profile,
        },

        // Users
        {
            path: '/users/',
            lazy: user.role === 'SHIPPER' ? UsersList : () => <Navigate to='/' />,
            redirectFrom: '/users',
        },

        // Company profile
        {
            path: '/company-profile/',
            lazy: user.role === 'PROVIDER' ? ProviderProfilePage : CustomerProfilePage,
            redirectFrom: '/company-profile',
        },

        // Logistic management
        ...getCustomerLogisticManagementRoutes(user, features),
        ...getLogisticProviderLogisticManagementRoutes(user, features),
        ...getLogisticProviderIntegrationsRoutes(user, features),
        // Warehouses
        ...getWarehouseRoutes(user, features),

        // Settings
        ...getSettingsRoutes(user, features),

        // Transport orders
        ...getTransportsRoutes(user),

        //  Default
        {
            path: '*',
            element: <DefaultNavigateLoggerUser />,
        },
    ];
};

const DefaultNavigateLoggerUser = () => {
    // This is the only case when we need the useUserContext out from the RouterContext,
    // because of login redirection.
    const { user } = useUserContext();
    const configurations = user?.customerWarehouseConfigurations ?? [];

    if (user?.transport?.isTransportOnly) {
        return <Navigate to={'/transports/'} />;
    }

    if (user.role === 'PROVIDER') {
        if (configurations.length > 0) {
            if (configurations.length === 1) {
                return <Navigate to={`/logistic-management/warehouses/${configurations[0].warehouseId}/orders/`} />;
            }
            return (
                <Navigate
                    to={
                        user?.features?.hasAnalyticsEnabled
                            ? '/logistic-management/analytics/'
                            : '/logistic-management/orders/'
                    }
                />
            );
        }
        return <Navigate to='/profile/' />;
    }
    if (user.role === 'SHIPPER') {
        if (configurations.length === 1) {
            return <Navigate to={`/logistic-management/warehouses/${configurations[0].warehouseId}/orders/`} />;
        }
        if (configurations.length > 1) {
            return (
                <Navigate
                    to={
                        user?.features?.hasAnalyticsEnabled
                            ? '/logistic-management/analytics/'
                            : '/logistic-management/orders/'
                    }
                />
            );
        }
    }
    return <Navigate to='/company-profile/' />;
};
