import { useSideBarContext } from '@spacefill/shared/src/utils/SideBarContext';
import { useTopBannerContext } from '@spacefill/shared/src/utils/TopBannerContext';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Link, Outlet } from 'react-router-dom';
import { BACKEND_URL_LIST } from '@spacefill/shared/src/constants/Backend';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';

import { ActionBarProps } from '../ActionBar/ActionBar';
import { BreadcrumbProps } from '../Breadcrumb';
import { SidebarProps } from '../Sidebar';

export interface AppLayoutProps {
    pageId?: string;
    dataTestid?: string;
    children: ReactNode[];
    leftNav?: ReactElement<SidebarProps>;
    breadcrumb?: ReactElement<BreadcrumbProps>;
    actionBar?: ReactElement<ActionBarProps>;
}

const AppLayout = ({ pageId, dataTestid, leftNav, breadcrumb }: AppLayoutProps) => {
    const { hasTopBanner, topBanner } = useTopBannerContext();
    const { isSizeBarCollapsed = false } = useSideBarContext() as unknown as {
        isSizeBarCollapsed: boolean;
        setIsSizeBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    };
    const backendUrl = getEnvironmentVariable('backendUrl');
    const featureTogglingEnabled = getEnvironmentVariable('featureTogglingEnabled');
    const showWarningMessage =
        (backendUrl === BACKEND_URL_LIST.PRODUCTION || backendUrl === BACKEND_URL_LIST.SANDBOX) &&
        featureTogglingEnabled;

    return (
        <>
            {showWarningMessage && (
                <div
                    className='fixed flex h-10 w-full items-center justify-center bg-red-500'
                    style={{ zIndex: 99999 }}
                >
                    <p className='text-center text-white'>
                        ⚠️ Warning: You are currently interacting with
                        {backendUrl === BACKEND_URL_LIST.PRODUCTION ? ' production' : ' sandbox'} data, not a local
                        environment. Please proceed with caution.
                    </p>
                </div>
            )}

            <div
                id='layout-viewport'
                className={`${showWarningMessage ? 'mt-10 h-[calc(100%-40px)]' : 'h-full'}  flex  grow bg-gray-50`}
            >
                {leftNav && (
                    <aside
                        id='layout-left-side'
                        className={`relative shrink-0 transition-all ${isSizeBarCollapsed ? 'w-16' : 'w-56'}`} // w-56 w-16 Must match with footer
                    >
                        {leftNav}
                    </aside>
                )}
                <div className='flex max-w-full grow flex-col overflow-y-auto'>
                    <div className='flex h-full flex-col'>
                        <header id='layout-header'>
                            {hasTopBanner && (
                                <Link
                                    id='notification-drawer'
                                    to={topBanner.link || '#'}
                                    onClick={typeof topBanner.onClick === 'function' ? topBanner.onClick : undefined}
                                    data-testid={topBanner.id}
                                    className='flex h-4 items-center justify-center border-none bg-sky-400 py-6 text-sm text-gray-50 no-underline outline-none hover:no-underline focus:no-underline'
                                >
                                    {topBanner.text}
                                </Link>
                            )}
                        </header>

                        {breadcrumb && <div>{breadcrumb}</div>}

                        <main
                            id={pageId}
                            data-testid={dataTestid}
                            className='grow px-6 py-8 pb-20'
                        >
                            <Outlet />
                        </main>
                    </div>
                    <div
                        className={`fixed bottom-0 right-0 ${
                            isSizeBarCollapsed ? 'left-16' : 'left-56'
                        } transition-all`}
                        id='app-layout-footer'
                    />
                </div>
            </div>
        </>
    );
};

const AppLayoutFooter = ({ children }: PropsWithChildren) => {
    const mountPoint = document.getElementById('app-layout-footer');
    if (mountPoint) {
        return createPortal(children, mountPoint);
    }
};

export { AppLayout, AppLayoutFooter };
