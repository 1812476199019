import React from 'react';
import { Navigate } from 'react-router-dom';

const LogisticManagementAddressBookEdit = async () =>
    import('../../pages/settings/address-book/Edit').then((module) => ({
        Component: module.LogisticManagementAddressBookEdit,
    }));
const LogisticManagementAddressBookNew = async () =>
    import('../../pages/settings/address-book/New').then((module) => ({
        Component: module.LogisticManagementAddressBookNew,
    }));
const SettingsViewPage = async () =>
    import('../../pages/settings/View').then((module) => ({ Component: module.SettingViewPage }));
const LogisticManagementCarrierEdit = async () =>
    import('../../pages/settings/carriers/Edit').then((module) => ({
        Component: module.LogisticManagementCarriersEdit,
    }));

export const getSettingsRoutes = (user, _features) => {
    return [
        // home
        {
            path: '/settings/',
            element: <Navigate to='/settings/users/' />,
            redirectFrom: '/settings',
        },
        // users
        {
            path: '/settings/users/',
            lazy: SettingsViewPage,
            redirectFrom: '/settings/users',
        },
        ...(user?.customerWarehouseConfigurations?.length > 0
            ? [
                  // users
                  {
                      path: '/settings/shipper-users/',
                      lazy: SettingsViewPage,
                      condition: !!user?.logisticProviderId,
                      redirectFrom: '/settings/shipper-users',
                  },
                  // storages
                  {
                      path: '/settings/storages/',
                      lazy: SettingsViewPage,
                      redirectFrom: '/settings/storages',
                  },
                  // warehouses
                  {
                      path: '/settings/warehouses/',
                      lazy: SettingsViewPage,
                      redirectFrom: '/settings/warehouses',
                  },
                  // address book
                  {
                      path: '/settings/address-book/',
                      lazy: SettingsViewPage,
                      condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER',
                  },
                  {
                      path: '/settings/address-book/:customerAddressId/edit',
                      lazy: LogisticManagementAddressBookEdit,
                      condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER',
                  },
                  {
                      path: '/settings/address-book/new',
                      lazy: LogisticManagementAddressBookNew,
                      condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER',
                  },
                  // custom field
                  {
                      path: '/settings/custom-fields/',
                      lazy: SettingsViewPage,
                      condition: user?.features?.orderManagementEnabled && user?.role === 'SHIPPER',
                  },
                  // Carriers page
                  {
                      path: '/settings/carriers/',
                      lazy: SettingsViewPage,
                      condition: user?.role === 'SHIPPER',
                  },
                  {
                      path: '/settings/carriers/:customerCarrierId/edit',
                      lazy: LogisticManagementCarrierEdit,
                      condition: user?.role === 'SHIPPER',
                  },
                  // Incidents types page
                  {
                      path: '/settings/incidents/',
                      lazy: SettingsViewPage,
                      condition: user?.role === 'SHIPPER',
                  },
                  // Display
                  {
                      path: '/settings/display/',
                      lazy: SettingsViewPage,
                      condition: user?.role === 'SHIPPER',
                  },
              ]
            : []),

        {
            path: '/settings/*',
            element: <Navigate to='/settings' />,
        },
    ];
};
