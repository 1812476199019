import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHandHoldingBox,
    faWarehouseFull,
    faAngleDoubleRight,
    faAngleDoubleLeft,
} from '@fortawesome/pro-light-svg-icons';
import { arrow, offset, safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { useSideBarContext } from '@spacefill/shared/src/utils/SideBarContext';

import { useMediaQuery } from '@spacefill/uikit/src/utils/useMediaQuery';

import { NavItem, NavItemProps } from './NavItem';
import logoSpacefill from './logo-spacefill.svg';

export interface UserInfoProps {
    userName: string;
    company: string;
    role?: string;
}

export type NavItemConfig = {
    subItems?: NavItemProps[];
} & NavItemProps;

export interface SidebarProps {
    mainNavigation?: NavItemConfig[];
    supportNavigation?: NavItemConfig[];
    bottomNavigation?: NavItemConfig[];
    profileTopNavigation?: NavItemConfig[];
    profileNavigation?: NavItemConfig[];
    userInfo: UserInfoProps;
    showUserSwitcher?: ReactNode;
    logo?: string | null;
    dataTestid?: string;
}

export const Sidebar = ({
    mainNavigation = [],
    bottomNavigation = [],
    profileNavigation = [],
    profileTopNavigation = [],
    showUserSwitcher = null,
    userInfo,
    logo = null,
    dataTestid,
}: SidebarProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const isSmallScreen = useMediaQuery('sm');
    const { isSizeBarCollapsed = isSmallScreen, setIsSizeBarCollapsed } = useSideBarContext() as unknown as {
        isSizeBarCollapsed: boolean;
        setIsSizeBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    };

    useEffect(() => {
        setIsSizeBarCollapsed(isSmallScreen);
    }, [isSmallScreen, setIsSizeBarCollapsed]);

    const handleResizeSidebar = () => {
        setIsSizeBarCollapsed(!isSizeBarCollapsed);
        window.localStorage.setItem('isSizeBarCollapsed', JSON.stringify(!isSizeBarCollapsed));
    };

    let hoverTimeout: NodeJS.Timeout | null = null;

    const handleMouseEnter = () => {
        if (hoverTimeout) clearTimeout(hoverTimeout);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        hoverTimeout = setTimeout(() => {
            setIsHovered(false);
        }, 200);
    };

    return (
        <nav
            role='navigation'
            className='relative z-sidebar flex h-full flex-col bg-zinc-900 transition-all'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovered && (
                <FontAwesomeIcon
                    className='absolute right-2 top-6 cursor-pointer bg-gray-900 p-1 text-sm text-gray-50'
                    icon={isSizeBarCollapsed ? faAngleDoubleRight : faAngleDoubleLeft}
                    onClick={handleResizeSidebar}
                />
            )}
            <div
                className='overflow-y-auto bg-zinc-900'
                // Force dark mode on sidebar to have a black scrollbar
                style={{ colorScheme: 'dark' }}
            >
                {showUserSwitcher ? (
                    showUserSwitcher
                ) : (
                    <div
                        className={`ml-2 h-[72px] overflow-hidden ${
                            isSizeBarCollapsed ? 'w-12' : 'w-52'
                        } flex items-center transition-all`}
                    >
                        <img
                            className={`${logo ? 'w-full' : 'w-36 pl-1'} max-w-36 cursor-pointer`}
                            alt='logo'
                            src={logo ?? logoSpacefill}
                            onClick={handleResizeSidebar}
                        />
                    </div>
                )}
                <ul className='flex flex-col gap-1 bg-zinc-900 px-2'>
                    {mainNavigation.map((navItem, idx) => (
                        <NavItem
                            key={`nav-item-${idx}`}
                            title={isSizeBarCollapsed ? '' : navItem.title}
                            icon={navItem.icon}
                            hasRoundedIcon={navItem.hasRoundedIcon}
                            bottomDivider={navItem.bottomDivider}
                            logoUrl={navItem.logoUrl ?? ''}
                            countValue={navItem.countValue}
                            isProFeature={navItem.isProFeature}
                            to={navItem.to}
                            isActive={navItem.isActive}
                            href={navItem.href}
                            target={navItem.target}
                            onClick={navItem.onClick}
                            dataTestid={navItem.dataTestid}
                        >
                            {navItem.subItems?.length && (
                                <ul data-testid='sidebar-navitem'>
                                    {navItem.subItems.map((subItem: NavItemConfig, subIdx) => (
                                        <NavItem
                                            key={`subnav-item-${idx}-subitem-${subIdx}`}
                                            title={subItem.title}
                                            className='mb-1'
                                            icon={subItem.icon}
                                            hasRoundedIcon={subItem.hasRoundedIcon}
                                            bottomDivider={subItem.bottomDivider}
                                            logoUrl={subItem.logoUrl}
                                            isProFeature={subItem.isProFeature}
                                            countValue={subItem.countValue}
                                            isActive={subItem.isActive}
                                            to={subItem.to}
                                            href={subItem.href}
                                            target={subItem.target}
                                            onClick={subItem.onClick}
                                            dataTestid={subItem.dataTestid}
                                            isSubNav
                                        />
                                    ))}
                                </ul>
                            )}
                        </NavItem>
                    ))}
                </ul>
            </div>

            <div className='mt-auto border-t border-solid border-t-zinc-600 pt-2'>
                <ul className='bg-zinc-900 px-2'>
                    {bottomNavigation.map((navItem, idx) => (
                        <NavItem
                            key={`nav-item-${idx}`}
                            title={isSizeBarCollapsed ? '' : navItem.title}
                            logoUrl={navItem.logoUrl ?? ''}
                            icon={navItem.icon}
                            hasRoundedIcon={navItem.hasRoundedIcon}
                            bottomDivider={navItem.bottomDivider}
                            countValue={navItem.countValue}
                            isProFeature={navItem.isProFeature}
                            isActive={navItem.isActive}
                            to={navItem.to}
                            href={navItem.href}
                            target={navItem.target}
                            onClick={navItem.onClick}
                            dataTestid={dataTestid && `${dataTestid}-title`}
                        >
                            {navItem.subItems?.length && (
                                <ul
                                    data-testid='sidebar-navitem'
                                    className={`origin-top rounded-md bg-zinc-800 p-2 transition`}
                                >
                                    {navItem.subItems.map((subItem: NavItemProps, subIdx) => (
                                        <NavItem
                                            key={`subnav-item-${idx}-subitem-${subIdx}`}
                                            title={subItem.title}
                                            className='mb-1'
                                            icon={subItem.icon}
                                            hasRoundedIcon={subItem.hasRoundedIcon}
                                            bottomDivider={navItem.bottomDivider}
                                            isProFeature={subItem.isProFeature}
                                            countValue={subItem.countValue}
                                            isActive={subItem.isActive}
                                            to={subItem.to}
                                            href={subItem.href}
                                            target={subItem.target}
                                            onClick={subItem.onClick}
                                            dataTestid={subItem.dataTestid}
                                            isSubNav
                                        />
                                    ))}
                                </ul>
                            )}
                        </NavItem>
                    ))}
                </ul>

                <div className='mt-auto bg-zinc-900'>
                    <ProfileNav
                        profileTopNavigation={profileTopNavigation}
                        profileNavigation={profileNavigation}
                        userInfo={userInfo}
                    />
                </div>
            </div>
        </nav>
    );
};

function ProfileNav({ profileTopNavigation = [], profileNavigation = [], userInfo }: SidebarProps) {
    const { isSizeBarCollapsed = false } = useSideBarContext() as unknown as {
        isSizeBarCollapsed: boolean;
        setIsSizeBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    };
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'right-end',
        middleware: [
            offset({ mainAxis: 6 }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const hover = useHover(context, {
        handleClose: safePolygon({
            buffer: 1,
        }),
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <div
            className='relative'
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            <div
                className={clsx(
                    'm-2 flex h-[52px] items-center gap-4 rounded-md p-2 text-sm font-medium text-white hover:cursor-pointer hover:font-bold',
                    {
                        'bg-zinc-700': isOpen,
                        'bg-zinc-800': !isOpen,
                    }
                )}
            >
                <FontAwesomeIcon
                    icon={userInfo.role === 'SHIPPER' ? faHandHoldingBox : faWarehouseFull}
                    className='w-3.5 rounded-full bg-zinc-700 p-2 text-white'
                />
                {!isSizeBarCollapsed && (
                    <div
                        className='truncate'
                        data-testid='user-info'
                    >
                        <p data-testid='name'>{userInfo.userName}</p>
                        <p
                            className='text-xs text-zinc-400'
                            data-testid='company'
                        >
                            {userInfo.company}
                        </p>
                    </div>
                )}
            </div>

            {isOpen && (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                    className={`z-sidebar rounded-md bg-zinc-800 p-2`}
                >
                    {profileTopNavigation.length > 0 && (
                        <Fragment>
                            <ul>
                                {profileTopNavigation.map((props, idx) => (
                                    <NavItem
                                        {...props}
                                        key={`profile-top-item-${idx}`}
                                    />
                                ))}
                            </ul>
                            <hr className='my-1 border-zinc-600' />
                        </Fragment>
                    )}
                    <ul>
                        {profileNavigation.map((props, idx) => (
                            <NavItem
                                {...props}
                                key={`profile-item-${idx}`}
                                isSubNav
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
