import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useTopBannerContext } from './TopBannerContext';
import { LocalFrontRequest } from './Axios';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const AutoRefresh = ({ localStorageKey }) => {
    const { t } = useTranslation();
    const { setTopBanner } = useTopBannerContext();

    const checkVersion = async () => {
        return LocalFrontRequest()
            .get(versionUrl)
            .then((result) => {
                const remoteHash = result.data.gitHash;
                const localHash = window.localStorage.getItem(localStorageKey);

                if (!localHash) {
                    window.localStorage.setItem(localStorageKey, remoteHash);
                    console.info(`${localStorageKey} git hash set to "${remoteHash}"`);
                    return;
                }

                if (remoteHash !== localHash) {
                    setTopBanner({
                        text: t('A new version is available. Please click here to reload your app.'),
                        id: 'please-reload-your-app',
                        onClick: () => {
                            window.localStorage.setItem(localStorageKey, remoteHash);
                            window.location.reload(true);
                        },
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    // eslint-disable-next-line no-undef
    const versionUrl = `${__ROUTER_BASE_NAME__}version.json`; // var is replace by ViteJS

    useEffect(() => {
        window.localStorage.removeItem(localStorageKey);
        LocalFrontRequest()
            .get(versionUrl)
            .then((result) => {
                const remoteHash = result.data.gitHash;
                window.localStorage.setItem(localStorageKey, remoteHash);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [localStorageKey, versionUrl]);

    useInterval(() => {
        checkVersion();
    }, 300000); // 5 minutes

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                checkVersion();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps -- I want to run this effect only once,
    }, []);

    return null;
};

export { AutoRefresh };
